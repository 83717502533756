<template>
  <div>
    <forms-edit></forms-edit>
  </div>
</template>


<script>
import FormsEdit from './../../components/forms/edit';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('forms.edit.title'),
    };
  },
  components: {
    FormsEdit
  }
}
</script>