<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">
      {{ $t("forms.edit.title") }}
    </div>
    <div>
      <div v-if="page === 'loading'" class="text-center mt-5">
        <v-progress-circular
          indeterminate
          color="secondary"
          width="4"
          size="60"
        ></v-progress-circular>
      </div>
      <form-forms
        :form="form"
        :sla.sync="sla"
        :events.sync="events"
        :progression.sync="progression"
        :schema.sync="schema"
        v-else-if="page === 'forms'"
      ></form-forms>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormForms from "./form.vue";
import { defaultFormsProgressState } from "./../../constants";

export default {
  created() {
    this.getForms();
  },
  data: () => ({
    page: "loading",
    loading: {},
    form: {},
    schema: [],
    sla: {},
    events: [],
    progression: {},
  }),
  methods: {
    ...mapActions({ request: "request", notifiy: "notification/notify" }),
    async getForms() {
      const params = this.$route.params;
      try {
        const response = await this.request({
          url: "forms.get",
          params: {
            formsId: params.id,
          },
          messages: {
            404: this.$t("forms.errors.404"),
          },
        });
        const data = response.data;
        this.form = {
          id: data.id,
          name: data.name,
          serviceId: data.serviceId,
        };
        this.schema = data.data.schema;
        this.events = data.data.events || [];
        this.progression = data.data.progress || {
          title: null,
          states: [{ ...defaultFormsProgressState }],
        };

        if (data.data.sla) {
          const sla = data.data.sla;
          this.sla = {
            identifier: sla._id,
            id: data.slaId,
            title: sla.title,
          };
        }

        this.page = "forms";
      } catch (error) {
        // empty
        this.page = "error";
      }
    },
  },
  components: {
    FormForms,
  },
};
</script>